<template>
    <List
        model="removalreasons"
        :can-delete="true"
        :can-edit="true"
        :can-view="false"
        default-sort="display_order"
        default-sort-order="asc"
    />
</template>
<script>
import List from "../common/list"
export default {
    components: { List },
}
</script>
